@import url('App.css');

.header {
  background-color: var(--primary-color-transparent);
  color: var(--text-light);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: visible; 
}

.header-decoration {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
}

.header-decoration.left {
  left: 0;  
}

.header-decoration.right {
  right: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative; 
  z-index: 2;
}

/* The glow effects */
.header-content::before,
.header-content::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  pointer-events: none;
}

.header-content::before {
  top: -175px;
  left: -70px;
  background: var(--glow-effect);
}

.header-content::after {
  top: -175px;
  right: -300px;
  background: var(--glow-effect);
}


.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 80px;
  width: auto;
  max-width: 200px;
  margin-right: 1rem;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}

.company-info {
  display: flex;
  flex-direction: column;
}

.company-name {
  font-size: 3.2rem;
  font-weight: bold;
  color: transparent;
  background-image: var(--gold-shine-text);
  background-clip: text;
  -webkit-background-clip: text;
}
.company-slogan {
  font-size: 1.2rem;  
  color: transparent;
  background-image: var(--gold-shine-text);
  background-clip: text;
  -webkit-background-clip: text;
}

.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 1.5rem;
}

.nav-links a, .nav-links button {
  color: var(--text-light);
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
}

.nav-links a:hover, .nav-links button:hover {
  color: transparent;
  background-image: var(--gold-shine-text);
  background-clip: text;
  -webkit-background-clip: text;
  transition: color 0.3s ease;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--primary-dark);
  padding: 0.5rem 0;
  border-radius: 4px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  z-index: 1010;
}

.dropdown-menu li {
  margin: 0;
  padding: 0;
}

.dropdown-menu a {
  color: var(--text-light);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  text-align: center;
  height: 100%;
  white-space: nowrap;
  list-style-type: none;
}

.dropdown-menu a:hover {
  background-color: var(--primary-light);
}

.dropdown ul {
  list-style-type: none; /* Add this line */
  padding: 0; /* Add this line */
  margin: 0; /* Add this line */
}

.mobile-menu-toggle {
  display: none;
}

@media (max-width: 1455px) {
  .header-decoration {
    display: none;
  }
}

@media (max-width: 990px) {
  .header-content {
    flex-wrap: wrap;
  }

  .mobile-menu-toggle {
    display: block;
    background: none;
    border: none;
    color: var(--text-light);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav {
    flex-basis: 100%;
    display: none;
  }

  .nav-open {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links li {
    margin: 0.5rem 0;
    width: 100%;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-menu {
    position: static;
    background-color: var(--primary-light);
    width: 100%;
  }

  .dropdown-menu a {
    justify-content: flex-start;
  }
}



/* For tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .header-content {
    padding: 0.75rem 1.5rem;
  }

  .logo {
    height: 60px;
  }

  .company-name {
    font-size: 2.4rem;
  }

  .company-slogan {
    font-size: 1rem;
  }

  .nav-links a, .nav-links button {
    font-size: 1rem;
  }

  .nav-links li {
    margin-left: 1.25rem;
  }

  .mobile-menu-toggle {
    font-size: 1.4rem;
  }
}

