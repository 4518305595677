@import './index.css';


.App {
  text-align: center;
  background-image: url('../images/background\ 2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.transparent-layer{
  background-color: var(--accent-1-light-super-transparent);
}

.container {
  max-width: 80rem;
  margin-left: 9rem;
  margin-right: 9rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 3%;
  margin-bottom: 6%;
}

body {
  margin: 0;
  padding: 0;  
  color: var(--text-dark);  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-content {
  position: relative;
  margin-top: 6%;
  padding: 2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;  
  background-color: var(--neutral-light-transparent);  
  text-align: center;
  overflow: hidden;
}

/* Green triangle on top left */
.main-content::before {
  content: '';
  position: absolute;
  top: 75%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8vw 0 8vw 8vw;
  border-color: transparent transparent transparent var(--primary-color-transparent);
  transform: translateY(-50%);
  z-index: 1;
}

/* Gold triangle on right side with shine effect */
.main-content::after {
  content: '';
  position: absolute;
  top: 25%;
  right: 0;
  width: 8vw;
  height: 18vw;
  background: var(--gold-shine);
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
  transform: translateY(-50%);
  z-index: 1;
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

@media (max-width: 768px) {
  .main-content::before{
    border-width: 8vw 0 8vw 8vw;
  }
  .main-content::after {
    width: 8vw;
    height: 16vw;
  }
}

/* Green stripe on top right */
.main-content .stripe-top-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 50px;
  background-color: var(--accent-1-transparent);
  transform: skew(0, -5deg) translateY(-25px);
}

/* Gold stripe on bottom left */
.main-content .stripe-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: var(--gold-shine);
  transform: skew(0, -5deg) translateY(25px);
}

.fade {
  transition: opacity 0.5s ease-in-out;
}

.fade.fadeIn {
  opacity: 1;
}

.fade.fadeOut {
  opacity: 0;
}



/* For tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 30%;
  }

  .main-content {
    margin-top: 4%;
    padding: 1.5rem;
  }

  .App-logo {
    height: 35vmin;
  }
}


