@import url('index.css');

@media (min-width: 640px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Hero Section */
.hero {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  background-color: var(--primary-color);
  color: var(--text-light);
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
}

/* The glow effects */
.hero::before,
.hero::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  pointer-events: none;
}

.hero::before {
  top: -175px;
  left: -70px;
  background: var(--glow-effect);
}

.hero::after {
  bottom: -175px;
  right: -70px;
  background: var(--glow-effect);
}

.hero h1 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: var(--gold-shine-text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* CTA Button */
.cta-button {
  background: var(--secondary-color);
  color: var(--text-dark);
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.cta-button:hover {
  background: var(--secondary-light);
}

/* Services Section */
.services-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.service-card {
  background-color: var(--neutral-light);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service-card svg {
  width: 3rem;
  height: 3rem;
  color: var(--accent-1);
}

.service-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.service-card p {
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.service-card a {
  color: var(--accent-1);
  font-weight: 600;
  text-decoration: none;
}

.service-card a:hover {
  color: var(--accent-1-light);
}

/* About Us Section */
.about-us {
  background-color: var(--neutral-light);
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  margin-bottom: 3rem;
}

.about-us h2 {
  font-size: 1.875rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.about-us p {
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.about-us a {
  color: var(--accent-1);
  font-weight: 600;
  text-decoration: none;
}

.about-us a:hover {
  color: var(--accent-1-light);
}

/* Call to Action Section */
.cta-section {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: var(--accent-1);
  color: var(--text-light);
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cta-section h2 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.cta-section .cta-button {
  background-color: var(--secondary-color);
  color: var(--text-dark);
}

.cta-section .cta-button:hover {
  background-color: var(--secondary-light);
}

/* Solution Promise Guarantee Section */
.solution-promise {
  background: var(--gold-shine);
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  margin-bottom: 3rem;
  color: var(--text-dark);
}

.solution-promise h2 {
  font-size: 1.875rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
}

.promise-content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.promise-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--accent-1);
  margin-right: 1rem;
  flex-shrink: 0;
}

.promise-content p {
  margin: 0;
  font-size: 1.1rem;
}

.promise-cta {
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5rem;
}

.solution-promise .cta-button {
  display: block;
  width: fit-content;
  margin: 0 auto;
  background: var(--primary-color);
  color: var(--secondary-color);
}

.solution-promise .cta-button:hover {
  background: var(--primary-light);
}

/* Inline Icon */
.inline-icon {
  vertical-align: middle;
  margin-left: 0.5rem;
}

/* Agents Page Specific Styles */
.agents-page {
  color: var(--text-dark);
}

/* FAQ Styles */
.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}

.faq-item {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: var(--primary-light);
}

.faq-answer {
  padding: 1rem;
  background-color: var(--neutral-light);
  color: var(--text-dark);
}

.faq-page .hero {
  margin-bottom: 2rem;
}

.faq-page .cta-section {
  margin-top: 3rem;
}



/* For tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .hero {
    padding: 1rem;
  }

  .hero h1 {
    font-size: 1.5rem;
  }

  .hero p {
    font-size: 0.9rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
  }

  .about-us {
    padding: 1.5rem;
  }

  .about-us h2 {
    font-size: 1.75rem;
  }

  .cta-section h2, .solution-promise h2 {
    font-size: 1.75rem;
  }

  .cta-section p, .solution-promise p {
    font-size: 1.1rem;
  }

  .cta-button {
    padding: 0.75rem 1.25rem;
    font-size: 1.1rem;
  }

  .promise-icon {
    display: none;
  }
}


