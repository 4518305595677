@import url('App.css');

.footer {
  background: var(--primary-color-transparent);
  color: var(--text-light);  
  width: 100%;  
  bottom: 0;
  left: 0;  
  overflow: hidden; 
  z-index: 1000;
}


.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;   
  align-items: center;
  padding: 1rem 2rem;   
  position: relative; 
  z-index: 2;
}

/* The glow effects */
.footer-content::before,
.footer-content::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  pointer-events: none;
}

.footer-content::before {
  bottom: -200px;
  left: -450px;
  background: var(--glow-effect);
}

.footer-content::after {
  bottom: -200px;
  right: -450px;
  background: var(--glow-effect);
}

.footer-section {
  flex: 1;
  margin-bottom: 1.5rem;
  min-width: 200px;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: transparent;
  background-image: var(--gold-shine-text);
  background-clip: text;
  -webkit-background-clip: text;;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: var(--text-light);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: transparent;
  background-image: var(--gold-shine-text);
  background-clip: text;
  -webkit-background-clip: text;;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: var(--primary-color-transparent);
  border-radius: 50%;
  font-size: 0.8rem;
  color: var(--text-light);
  transition: transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
}

.footer-bottom {
  text-align: center;
  padding: 1rem;
  background-color: var(--primary-dark);
  color: var(--text-light);
  border-top: 1px solid var(--neutral-medium);
}

.footer-bottom p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.footer-bottom a {
  color: var(--secondary-light);
  text-decoration: underline;
  transition: color 0.3s ease;
}

.footer-bottom a:hover {
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-section {
    margin-bottom: 2rem;
  }
}


/* For phones (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .footer-content {
    padding: 0.5rem 1rem;
  }

  .footer-section h3 {
    font-size: 1rem;
  }

  .social-links a {
    width: 25px;
    height: 25px;
    font-size: 0.7rem;
  }

  .footer-bottom {
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }
}

/* For tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .footer-content {
    padding: 0.75rem 1.5rem;
  }

  .footer-section h3 {
    font-size: 1.1rem;
  }

  .social-links a {
    width: 28px;
    height: 28px;
    font-size: 0.75rem;
  }

  .footer-bottom {
    padding-top: 0.75rem;
    margin-top: 0.75rem;
  }
}

  
