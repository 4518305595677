:root {
  --transparent-scale: 0.95;
  --glow-scale-1: 0.2;
  --glow-scale-0: 0;
  /* Primary Colors */
  --primary-color: rgba(49, 58, 15, 1);  /* Dark Green */
  --primary-color-transparent: rgba(49, 58, 15, var(--transparent-scale));
  --primary-dark: rgba(34, 43, 11, 1);
  --primary-dark-transparent: rgba(34, 43, 11, var(--transparent-scale));
  --primary-light: rgb(98, 117, 31); /* Lighter Green */
  --primary-light-transparent: rgba(142, 170, 44, var(--transparent-scale));

  /* Secondary Colors (now Gold) */
  --secondary-color: rgba(170, 141, 58, 1);        /* Gold */
  --secondary-color-transparent: rgba(170, 141, 58, var(--transparent-scale));        /* Gold */
  --secondary-color-glow-1: rgba(170, 141, 58, var(--glow-scale-1));
  --secondary-color-glow-0: rgba(170, 141, 58, var(--glow-scale-0));
  --secondary-dark: rgba(136, 113, 46, 1);   /* Darker Gold */
  --secondary-dark-transparent: rgba(136, 113, 46, var(--transparent-scale));   /* Darker Gold */
  --secondary-dark-glow-1: rgba(136, 113, 46, var(--glow-scale-1));
  --secondary-dark-glow-0: rgba(136, 113, 46, var(--glow-scale-0));
  --secondary-light: rgba(228, 193, 94, 1);  /* Lighter Gold */
  --secondary-light-transparent: rgba(228, 193, 94, var(--transparent-scale));  /* Lighter Gold */
  --secondary-light-glow-1: rgba(228, 193, 94, var(--glow-scale-1));
  --secondary-light-glow-0: rgba(228, 193, 94, var(--glow-scale-0));

  /* Accent Colors (now Purple) */
  --accent-1: rgba(60, 23, 82, 1);  /* Deep Purple */
  --accent-1-transparent: rgba(60, 23, 82, var(--transparent-scale));  /* Deep Purple */
  --accent-1-dark: rgba(48, 18, 66, 1);    /* Darker Purple */
  --accent-1-dark-transparent: rgba(48, 18, 66, var(--transparent-scale));    /* Darker Purple */
  --accent-1-light: rgba(116, 71, 175, 1);  /* Lighter Purple */
  --accent-1-light-transparent: rgba(116, 71, 175, var(--transparent-scale));  /* Lighter Purple */
  --accent-1-light-super-transparent: rgba(60, 23, 82, 0.6); 

  /* Neutral Colors */
  --neutral-dark: rgba(51, 51, 51, 1);      /* Dark Gray */
  --neutral-dark-transparent: rgba(51, 51, 51, var(--transparent-scale));      /* Dark Gray */
  --neutral-medium: rgba(119, 119, 119, 1); /* Medium Gray */
  --neutral-medium-transparent: rgba(119, 119, 119, var(--transparent-scale)); /* Medium Gray */
  --neutral-light: rgb(255, 255, 255);  /* Light Gray */
  --neutral-light-transparent: rgba(255, 255, 255, var(--transparent-scale));  /* Light Gray */

  /* Text Colors */
  --text-dark: rgba(33, 33, 33, 1);
  --text-light: rgba(255, 255, 255, 1);
  --text-dark-transparent: rgba(33, 33, 33, var(--transparent-scale));
  --text-light-transparent: rgba(255, 255, 255, var(--transparent-scale));

  /* Background Colors */
  --bg-dark: rgba(18, 18, 18, 1);   /* Very Dark Gray */
  --bg-light: rgb(171, 171, 171);
  --bg-dark-transparent: rgba(18, 18, 18, var(--transparent-scale));   /* Very Dark Gray */
  --bg-light-transparent: rgba(171, 171, 171, var(--transparent-scale));


  /* Additional Accent */
  --accent-2: rgba(230, 194, 0, 1); /* Muted Gold */
  --accent-2-transparent: rgba(230, 194, 0, var(--transparent-scale));

  --gold-shine-text: linear-gradient(4deg, var(--secondary-color) 30%, var(--secondary-light) 50%, var(--secondary-color) 70%);
  --gold-shine: linear-gradient(4deg, var(--secondary-color) 30%, var(--secondary-light) 50%, var(--secondary-color) 70%);

  --glow-effect: radial-gradient(circle, var(--secondary-light-glow-1) 0%, var(--secondary-light-glow-0) 70%);
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--primary-color);
}

body {
  display: flex;
  flex-direction: column;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  line-height: 1.6;
}

button {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}


.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero button {
  background-color: #333;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 20px;
}

.features {
  padding: 4rem 0;
}

.feature-list {
  display: flex;
  justify-content: space-between;
}

.feature {
  flex-basis: 30%;
}

/* For phones (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .hero h1 {
    font-size: 1.8rem;
  }

  .hero button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .feature-list {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
}

/* For tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .hero h1 {
    font-size: 2.2rem;
  }

  .hero button {
    font-size: 0.95rem;
    padding: 9px 18px;
  }

  .feature {
    flex-basis: 45%;
  }
}

